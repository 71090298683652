html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'Montserrat' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

.nav-items {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-items-guest {
  width: 70%;
}

.login-signup-fields {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.search-bar {
  margin-top: 60px;
}
.title {
  margin-bottom: 30px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
}

.menu-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.search-bar {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.search-results {
  position: absolute;
  width: 340px;
  background: #fff;
  padding: 8px;
  border-radius: 8px;
}

.hero-block {
  position: relative;
}

.hero-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  margin-bottom: 16px;
}

.hero-subtitle {
  margin: 0 130px 0 130px;
}

.accout-text {
  padding-bottom: 4px;
}

.create-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.menu-items {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.course-button {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-button .button-and-icon {
  display: flex;
  align-items: center;
}

.courses-page-side-menu {
  padding: 10px 30px 0 30px;
}
.explore-title {
  margin-bottom: 50px;
  margin-top: 80px;
}

.explore-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 80px;
  margin-top: 36px;
}

.show-more-button {
  display: flex;
  justify-content: center;
}

.rating-img {
  gap: 10px;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.side-menu {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.side-menu-item {
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  padding-top: 17px;
  align-items: center;
  margin-left: 10px;
}

.side-menu-container {
  position: relative;
}

.side-menu-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #d5d5d5;
}

.horizontal-line {
  border-bottom: 1px solid #d5d5d5;
  width: 100%;
}

.load-more-button {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.footer-newsletter-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer {
  height: 324px;
  width: 1440px;
  background-color: #23231a;
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.footer-section {
  background-color: #23231a;
  padding-top: 50px;
  padding-bottom: 80px;
}

.footer-home-section {
  margin-top: 14px;
}

.footer-policies-section,
.footer-about-section,
.footer-home-section {
  gap: 30px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.footer-policies-section a:hover .MuiTypography-root,
.footer-about-section a:hover .MuiTypography-root,
.footer-home-section a:hover .MuiTypography-root {
  color: #e4751b;
}
.spotit-text-section {
  margin-top: 10px;
}

.footer-top-block {
  display: flex;
  justify-content: center;
  height: 151px;
  background-color: #ebf0f4;
  box-sizing: border-box;
  width: 100vw;
  overflow: auto;
  min-height: 0;
}

.footer-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 24px 0 24px;
  gap: 70px;
}

.social-icons {
  margin-top: 10px;
}

.signup-container {
  margin-top: 100px;
  width: 100%;
}

.login-container {
  margin-top: 100px;
  width: 100%;
}

.benefits-container {
  background-color: #fee6c2;
  padding: 60px;
}

.benefits-section {
  display: flex;
  margin-top: 50px;
  align-items: center;
  margin-bottom: 20px;
}

.course-benefits {
  display: flex;
  gap: 30px;

  margin: 20px;
  align-items: center;
  height: 80px;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

#signup-form {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
}

.explore-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  margin-top: 138px;
}

.wishlist-block {
  width: 100%;
  margin-top: -15px;
  height: 138px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.my-wishlist {
  position: relative;
  margin-right: 25px;
}

.wishlist-block--background-div {
  background-color: #ebf0f5;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
}

.my-wishlist {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 200px;
}

.bookmark-wishlist-img {
  width: 14px;
  height: 20px;
  margin-right: 20px;
}

.title-text-favorite {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.favorite-card-container {
  padding: 0 30px 0 30px;
  margin-top: 20px;
}

.favourite-button {
  margin-top: auto;
  margin-bottom: 40px;
  display: flex;
  align-items: end;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
}

.privacy-title {
  margin-top: 60px;
  margin-bottom: 30px;
}

.privacy-policy-subtext {
  /* margin-bottom: 20px; */
  margin-top: 30px;
}

.privacy-policy-main-text {
  margin-top: 15px;
}

.privacy-policy-main-text br {
  margin-top: 0; /* Removes the top margin */
  margin-bottom: 0; /* Removes the bottom margin */
}

@media (max-width: 600px) {
  .title {
    margin-bottom: '10px';
    padding: '5%';
  }
  .text-container {
    padding: 0;
  }
  .subtitle {
    padding: '5px';
  }
  .text-container {
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
  }
  .landing {
    margin-top: 30px;
  }
  .container-landing {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .search-bar {
    margin-top: 0;
    width: 100%;
  }
  .search-bar {
    margin-top: 0;
  }

  .explore-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 36px;
    margin-top: 30px;
  }

  .explore-title {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .course-button {
    margin-top: 0;
  }
}

.filter-options {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
}

.login-container fieldset,
.signup-container fieldset {
  border-color: transparent;
}

.forgot-password {
  display: flex;
  justify-content: end;
}

.global-highlight-span {
  color: #e4751b;
}

.global-a-hovers:hover * {
  color: #003F91;
}
